import React from 'react'
const HeightIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="64.442" height="48.224" viewBox="0 0 64.442 48.224">
	  <g id="Group_2183" data-name="Group 2183" transform="translate(-1209.246 -459)">
	    <g id="Group_2182" data-name="Group 2182" transform="translate(18217 8800.225)">
	      <path id="Path_11546" data-name="Path 11546" d="M385.174,69.02l-11.645.573L343.292,84.448l11.731-.514Z" transform="translate(-17342.85 -8410.221)" fill="#6c9da8"/>
	      <path id="Path_11547" data-name="Path 11547" d="M369.768,121.676l-14.745-14.108-11.73.514Z" transform="translate(-17342.85 -8433.855)" fill="#b7c1c4"/>
	      <path id="Path_11548" data-name="Path 11548" d="M400.11,133.359l29.511-14.946.293-18.657-29.8,14.558Z" transform="translate(-17373.227 -8426.494)" fill="#54666d"/>
	      <path id="Path_11550" data-name="Path 11550" d="M412.926,83.491,398.617,69.22l-30.15,14.714,14.745,14.108Z" transform="translate(-17356.311 -8410.221)" fill="#44717f"/>
	      <path id="Path_11551" data-name="Path 11551" d="M343.292,108.671v19.076l13.3,6.849,13.18,6.714V122.265Z" transform="translate(-17342.85 -8434.44)" fill="#6c9da8"/>
	      <path id="Path_11552" data-name="Path 11552" d="M343.292,110.668v18.145l26.476,13.563V124.164Z" transform="translate(-17342.85 -8435.504)" fill="#a7b3b7"/>
	      <path id="Path_11553" data-name="Path 11553" d="M368.607,120.725l-12.893-12.534-10.507.53-.621-.329,11.128-.824,14.2,13.826Z" transform="translate(-17343.588 -8433.855)" fill="#7a7a7a"/>
	      <path id="Path_11554" data-name="Path 11554" d="M368.962,121.021l-14.019-13.483-11.048.613-.6-.31,11.81-.725,14.666,14.318Z" transform="translate(-17342.85 -8433.605)" fill="#eaeaea"/>
	      <path id="Path_11557" data-name="Path 11557" d="M370.265,139.07,350.01,128.736V118.571l20.255,10.268Z" transform="translate(-17346.438 -8439.727)" fill="#a8a8a8"/>
	      <path id="Path_11558" data-name="Path 11558" d="M350.01,119.454v1.09l20.255,10.268v-1.09Z" transform="translate(-17346.439 -8440.201)" fill="#fff"/>
	      <path id="Path_11559" data-name="Path 11559" d="M350.01,123.455l20.255,10.268v-1.09L350.01,122.365Z" transform="translate(-17346.439 -8441.755)" fill="#fff"/>
	      <path id="Path_11560" data-name="Path 11560" d="M350.01,126.377l20.255,10.268v-1.09L350.01,125.287Z" transform="translate(-17346.439 -8443.299)" fill="#fff"/>
	      <path id="Path_11561" data-name="Path 11561" d="M350.01,129.289l20.255,10.268v-1.09L350.01,128.2Z" transform="translate(-17346.439 -8444.863)" fill="#fff"/>
	      <path id="Path_11562" data-name="Path 11562" d="M350.01,132.167l20.255,10.268v-1.09L350.01,131.077Z" transform="translate(-17346.439 -8446.393)" fill="#fff"/>
	      <path id="Path_11563" data-name="Path 11563" d="M350.01,135.078l20.255,10.268v-1.09L350.01,133.988Z" transform="translate(-17346.439 -8447.945)" fill="#fff"/>
	      <path id="Path_11564" data-name="Path 11564" d="M350.01,138l20.255,10.268v-1.09L350.01,136.911Z" transform="translate(-17346.439 -8449.519)" fill="#fff"/>
	      <path id="Path_11565" data-name="Path 11565" d="M350.01,120.623v.545l20.255,10.268v-.545Z" transform="translate(-17346.439 -8440.822)" fill="#d6d6d6"/>
	      <path id="Path_11566" data-name="Path 11566" d="M350.01,124.08l20.255,10.268V133.8L350.01,123.535Z" transform="translate(-17346.439 -8442.374)" fill="#d6d6d6"/>
	      <path id="Path_11567" data-name="Path 11567" d="M350.01,127l20.255,10.268v-.545L350.01,126.458Z" transform="translate(-17346.439 -8443.938)" fill="#d6d6d6"/>
	      <path id="Path_11568" data-name="Path 11568" d="M350.01,129.913l20.255,10.268v-.545L350.01,129.369Z" transform="translate(-17346.439 -8445.487)" fill="#d6d6d6"/>
	      <path id="Path_11569" data-name="Path 11569" d="M350.01,132.792l20.255,10.268v-.545L350.01,132.246Z" transform="translate(-17346.439 -8447.03)" fill="#d6d6d6"/>
	      <path id="Path_11570" data-name="Path 11570" d="M350.01,135.7l20.255,10.268v-.545L350.01,135.158Z" transform="translate(-17346.439 -8448.581)" fill="#d6d6d6"/>
	      <path id="Path_11571" data-name="Path 11571" d="M350.01,138.626l20.255,10.268v-.545L350.01,138.081Z" transform="translate(-17346.439 -8450.143)" fill="#d6d6d6"/>
	      <path id="Path_11573" data-name="Path 11573" d="M343.292,147.385l26.535,13.509v1.211l-26.535-13.685Z" transform="translate(-17342.85 -8455.106)" fill="#eaeaea"/>
	      <path id="Path_11575" data-name="Path 11575" d="M400.146,152.771l29.495-14.892v1.038l-29.5,14.94Z" transform="translate(-17373.217 -8447.015)" fill="#a7b3b7"/>
	      <rect id="Rectangle_1374" data-name="Rectangle 1374" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16985.928, -8327.188)" fill="#02424c"/>
	      <rect id="Rectangle_1375" data-name="Rectangle 1375" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16984.258, -8327.982)" fill="#02424c"/>
	      <rect id="Rectangle_1376" data-name="Rectangle 1376" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16982.623, -8328.806)" fill="#02424c"/>
	      <rect id="Rectangle_1377" data-name="Rectangle 1377" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16980.979, -8329.634)" fill="#02424c"/>
	      <rect id="Rectangle_1378" data-name="Rectangle 1378" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16979.311, -8330.429)" fill="#02424c"/>
	      <rect id="Rectangle_1379" data-name="Rectangle 1379" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16977.643, -8331.241)" fill="#02424c"/>
	      <rect id="Rectangle_1380" data-name="Rectangle 1380" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16975.975, -8332.066)" fill="#02424c"/>
	      <rect id="Rectangle_1381" data-name="Rectangle 1381" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16974.332, -8332.862)" fill="#02424c"/>
	      <rect id="Rectangle_1382" data-name="Rectangle 1382" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16972.662, -8333.679)" fill="#02424c"/>
	      <rect id="Rectangle_1383" data-name="Rectangle 1383" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16970.992, -8334.486)" fill="#02424c"/>
	      <rect id="Rectangle_1384" data-name="Rectangle 1384" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16969.326, -8335.298)" fill="#02424c"/>
	      <rect id="Rectangle_1385" data-name="Rectangle 1385" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16967.711, -8336.125)" fill="#02424c"/>
	      <rect id="Rectangle_1386" data-name="Rectangle 1386" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16966.049, -8336.92)" fill="#02424c"/>
	      <rect id="Rectangle_1387" data-name="Rectangle 1387" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16964.375, -8337.731)" fill="#02424c"/>
	      <rect id="Rectangle_1388" data-name="Rectangle 1388" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16962.732, -8338.561)" fill="#02424c"/>
	      <rect id="Rectangle_1389" data-name="Rectangle 1389" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16961.064, -8339.354)" fill="#02424c"/>
	      <rect id="Rectangle_1390" data-name="Rectangle 1390" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16959.402, -8340.184)" fill="#02424c"/>
	      <rect id="Rectangle_1391" data-name="Rectangle 1391" width="0.233" height="20.225" transform="matrix(0.709, -0.705, 0.705, 0.709, -16957.73, -8340.976)" fill="#02424c"/>
	      <path id="Path_11576" data-name="Path 11576" d="M386.973,68.881h-.223l-11.422.594-.51.251v.008l11.4-.592-1.146.559-11.4.593-.5.248v.011l11.372-.591-1.145.559L372,71.113l-.5.245v.013l11.348-.59-1.146.559-11.367.591-.493.243v.016l11.325-.589-1.146.56-11.35.589-.486.24v.019l11.3-.588-1.145.559-11.332.589-.481.237v.021l11.277-.586-1.146.559-11.314.588-.475.234v.024l11.253-.585-1.145.559-11.3.587-.47.232v.026l11.229-.584-1.146.559-11.278.586-.463.228v.029l11.2-.582-1.146.559-11.26.585-.457.226,0,.032,11.181-.582-1.145.559-11.242.584-.452.223,0,.034,11.158-.58-1.146.559-11.224.583-.445.219,0,.037,11.133-.579-1.146.559-11.206.582-.439.217,0,.039,11.109-.577-1.145.559-11.189.582-.434.214,0,.041L364.37,79.8l-1.146.559-11.17.581-.428.211,0,.044,11.062-.575-1.146.56-11.153.579-.422.208,0,.048,11.037-.574L359.864,82l-11.134.579-.417.205,0,.049,11.014-.572-1.146.559-11.117.578-.41.2,0,.053,10.989-.571-1.1.537.348.194,30.251-14.8Z" transform="translate(-17344.648 -8410.105)" fill="#44717f"/>
	      <path id="Path_11577" data-name="Path 11577" d="M368.947,77.808l-.838.03L398.463,63.06l.1.147Z" transform="translate(-17356.09 -8404.266)" fill="#02424c"/>
	      <path id="Path_12819" data-name="Path 12819" d="M400.11,115.147l29.8-14.7v-.766l-29.8,14.632Z" transform="translate(-17373.227 -8426.494)" fill="#02424c"/>
	    </g>
	    <g id="Group_2183-2" data-name="Group 2183" transform="translate(-7217.254 17581.25) rotate(90)">
	      <path id="Union_1" data-name="Union 1" d="M-296.381,43.81l-2.417-2.348a.638.638,0,0,1,0-.923l2.417-2.348a.687.687,0,0,1,.949,0,.638.638,0,0,1,0,.923,3.748,3.748,0,0,0-.793.886h22.2a3.749,3.749,0,0,0-.794-.886.64.64,0,0,1,0-.923.687.687,0,0,1,.95,0l2.416,2.348a.637.637,0,0,1,0,.922l-2.416,2.348a.679.679,0,0,1-.475.191.679.679,0,0,1-.475-.191.64.64,0,0,1,0-.922,3.75,3.75,0,0,0,.793-.886h-22.2a3.745,3.745,0,0,0,.794.887.638.638,0,0,1,0,.922.679.679,0,0,1-.474.191A.683.683,0,0,1-296.381,43.81Z" transform="translate(-16811.254 -8471)" fill="#ed602b" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1"/>
	    </g>
	  </g>
	</svg>
)

export default HeightIcon